import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import CustomerItem from "./CustomerItem";
import AddEditCustomer from "./CustomerActions/AddEditCustomer";
import Modal from "../../../../../../../Generic/Modal";
import { useSiteContext } from "../../../../../../../Context";

function CustomerManagement(props) {
  const [customers, SetCustomers] = useState([]);
  const [showAddEditCustomerModal, SetShowAddEditCustomerModal] =
    useState(false);
  const { GetData } = useSiteContext();

  async function GetCustomers() {
    let newCustomer = await GetData("Customer", {
      orderBy: "name",
    });
    if (newCustomer instanceof Array) {
      SetCustomers(newCustomer);
    }
  }

  function AddEditCustomerModal() {
    SetShowAddEditCustomerModal(!showAddEditCustomerModal);
  }

  useEffect(() => {
    GetCustomers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="adminModal tableOnly">
        <div className="adminModalBodyContainer tableOnly">
          <div className="adminModalTitleBar">
            <div className="adminModalTitle">Customer Management</div>
          </div>
          <div className="adminModalBody">
            <div className="adminModalBodyItemContainer customerItem header">
              <div className="adminModalBodyHeaderItem">Customer</div>
              <div className="adminModalBodyHeaderItem hiddenOnSmall">
                Phone
              </div>
              <div className="adminModalBodyHeaderItem">Edit</div>
              <div className="adminModalBodyHeaderItem">Feat</div>
            </div>
            {customers
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((c) => (
                <CustomerItem
                  customer={c}
                  key={c.id}
                  RefreshCustomers={GetCustomers}
                />
              ))}
          </div>
          <div className="adminModalTitleBar">
            <Icon
              className="managementAddIcon"
              icon="fluent:add-circle-16-filled"
              onClick={AddEditCustomerModal}
            />
          </div>
        </div>
      </div>
      {showAddEditCustomerModal ? (
        <Modal
          CloseModal={AddEditCustomerModal}
          modificationClass="noPaddingAlternate"
        >
          <AddEditCustomer
            CloseModal={AddEditCustomerModal}
            RefreshCustomers={GetCustomers}
          />
        </Modal>
      ) : null}
    </>
  );
}

export default CustomerManagement;
