import React, { useState } from "react";
import { Icon } from "@iconify/react";
import Modal from "../../../../../../../Generic/Modal";
import AddEditClient from "./ClientActions/AddEditClient";

function ClientItem(props) {
  const [showAddEditClientModal, SetShowAddEditClientModal] = useState(false);

  function AddEditClientModal() {
    SetShowAddEditClientModal(!showAddEditClientModal);
  }

  return (
    <>
      <div className="adminModalBodyItemContainer clientItem">
        <div className="adminModalTableValue">{props.client.name}</div>
        <div className="adminModalTableValue">
          <Icon
            className="editIcon"
            icon="bxs:edit"
            onClick={AddEditClientModal}
          />
        </div>
      </div>
      {showAddEditClientModal ? (
        <Modal
          CloseModal={AddEditClientModal}
          modificationClass="noPaddingAlternate"
        >
          <AddEditClient
            editMode={true}
            CloseModal={AddEditClientModal}
            client={props.client}
            RefreshClients={props.RefreshClients}
          />
        </Modal>
      ) : null}
    </>
  );
}

export default ClientItem;
