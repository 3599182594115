import React, { useRef } from "react";
import { Icon } from "@iconify/react";
import ErrorBoundary from "./ErrorBoundary";
import { useSiteContext } from "../Context";

function Modal({ CloseModal, children, modificationClass = "" }) {
  const currentModalContainer = useRef(null);

  const { loggedInUser, AddData } = useSiteContext();

  function CloseModalOnClickBackground(e) {
    if (e.target === currentModalContainer.current) {
      CloseModal();
    }
  }

  return (
    <div
      className={"modalContainer " + modificationClass}
      onMouseDown={CloseModalOnClickBackground}
      ref={currentModalContainer}
    >
      <div className={"modalMain " + modificationClass}>
        <div className={"modalMainInner " + modificationClass}>
          <ErrorBoundary loggedInUser={loggedInUser} AddData={AddData}>
            {children}
          </ErrorBoundary>
          <div
            className={"modalCloseButton " + modificationClass}
            type="button"
            onClick={CloseModal}
          >
            <Icon icon="akar-icons:circle-x" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
