import * as pdfjsLib from "pdfjs-dist";

export function CustomSort(a, b, isString, isInverted) {
  let aProcessed = a;
  let bProcessed = b;
  let inverted = 1;
  let value = 0;
  if (isInverted) {
    inverted = -1;
  }
  if (isString) {
    if (aProcessed == null) value = 1;
    if (bProcessed == null) value = -1;
    aProcessed = a.toLowerCase();
    bProcessed = b.toLowerCase();
  }
  if (aProcessed < bProcessed) value = 1;
  if (aProcessed > bProcessed) value = -1;
  return value * inverted;
}

export function FormatDate(date) {
  return date.toISOString().substring(0, 10);
}

export function FormatPhone(phone) {
  if (phone.length >= 7) {
    return `(${phone.substring(0, 3)}) ${phone.substring(
      3,
      6
    )}-${phone.substring(6)}`;
  }
  return phone;
}

export function ValidateField(Type, Value) {
  switch (Type) {
    case "Email":
      if (Value.length > 0 && Value.includes("@") && Value.includes(".")) {
        return true;
      }
      return false;
    case "Phone":
      if (Value.length === 10) {
        return true;
      }
      return false;
    case "Text":
      if (Value.length > 0) {
        return true;
      }
      return false;
    case "Number":
      if (Value.length > 0 && !isNaN(Value)) {
        return true;
      }
      return false;
    case "zipCode":
      if ((Value.length === 5 || Value.length === 9) && !isNaN(Value)) {
        return true;
      }
      return false;
    case "Password":
      const regex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-+_!@#$%^&*.,/\\?]).+$/;
      if (Value.length >= 8 && regex.test(Value)) {
        return true;
      }
      return false;
    default:
      return false;
  }
}

export function TruncateString(str, num) {
  if (num == null) {
    num = 30;
  }
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num - 3) + "...";
}

export function CheckIfObjectChanged(item1, item2) {
  let changedKeys = [];
  let item1Keys = Object.keys(item1);
  for (let i = 0; i < item1Keys.length; i++) {
    let key = item1Keys[i];
    if (item1[key] instanceof Array) {
      continue;
    }
    if (item1[key] instanceof Object) {
      let subChangedKeys = CheckIfObjectChanged(item1[key], item2[key]);
      if (subChangedKeys.length > 0) {
        for (let j = 0; j < subChangedKeys.length; j++) {
          changedKeys.push(key + "." + subChangedKeys[j]);
        }
      }
      continue;
    }
    if (item1[key] !== item2[key]) {
      changedKeys.push(key);
    }
  }
  return changedKeys;
}

export function GenerateGanttItemStatusParameter(
  fieldName,
  label,
  value,
  type,
  importanceForOrdering,
  editable = false,
  ChangeFunction,
  arrayAssignable,
  displayInsteadOfValue,
  showBlankInDropdown = false,
  showDefaultInDropdown = false,
  required = false,
  sortBy = "",
  minDate = null,
  maxDate = null
) {
  let itemParameter = {
    fieldName: fieldName,
    label: label,
    value: value,
    type: type,
    importanceForOrdering: importanceForOrdering,
    editable: editable,
    ChangeFunction: ChangeFunction,
    arrayAssignable: arrayAssignable,
    displayInsteadOfValue: displayInsteadOfValue,
    showBlankInDropdown: showBlankInDropdown,
    showDefaultInDropdown: showDefaultInDropdown,
    required: required,
    sortBy: sortBy,
    minDate: minDate,
    maxDate: maxDate,
  };
  return itemParameter;
}

export function CheckIfArraysAreEqual(array1, array2) {
  if (array1.length !== array2.length) {
    return false;
  }
  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      if (array1[i] instanceof Object) {
        return CheckIfObjectChanged(array1[i], array2[i]).length === 0;
      } else {
        return false;
      }
    }
  }
  return true;
}

export function RoundToNearest(number, nearest, upOrDown, decimalPlaces = 2) {
  let multiplyOrDivideBy = 1;
  let MathFunction = Math.round;
  switch (upOrDown) {
    case "up":
      MathFunction = Math.ceil;
      break;
    case "down":
      MathFunction = Math.floor;
      break;
    default:
      break;
  }
  switch (nearest) {
    case 0.25:
      multiplyOrDivideBy = 4;
      break;
    case 0.5:
      multiplyOrDivideBy = 2;
      break;
    default:
      break;
  }
  let result = MathFunction(number * multiplyOrDivideBy) / multiplyOrDivideBy;
  result = result.toFixed(decimalPlaces);
  return result;
}

export function GetDateDisplay(dateString) {
  if (dateString == null) {
    return "";
  }
  if (dateString.length === 0) {
    return "";
  }
  if (dateString.includes("/")) {
    return dateString.replace(",", "").replace(" ", "");
  }
  let date = new Date(dateString);
  let timezoneOffset = date.getTimezoneOffset();
  date = new Date(date.getTime() + timezoneOffset * 60 * 1000);
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let year = date.getFullYear();
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }
  return `${month}/${day}/${year}`;
}

export function EmailSupport(e) {
  window.location.href = "mailto: support@foragerhq.com";
  e.preventDefault();
}

export function RefreshPage(e) {
  window.location.reload();
  e.preventDefault();
}

export function GenerateRandomString(length) {
  let result = "";
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function GetCountCalledWithTimedReset() {
  let count = 0;
  let lastCalled = 0;
  return function () {
    let now = new Date().getTime();
    if (now - lastCalled > 1000) {
      count = 0;
    }
    lastCalled = now;
    count++;
    return count;
  };
}

export function CapitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function ProcessCamelCaseString(string) {
  let result = "";
  let lastWasANumber = false;
  for (let i = 0; i < string.length; i++) {
    let skip = lastWasANumber && !isNaN(string[i]);
    if (i > 0 && string[i] === string[i].toUpperCase() && !skip) {
      result += " " + string[i];
    } else {
      result += string[i];
    }
    lastWasANumber = !isNaN(string[i]);
  }
  return result;
}

export function GetUniqueObjectsFromArray(array, key) {
  let uniqueArray = [];
  let uniqueObject = {};
  for (let i = 0; i < array.length; i++) {
    if (!uniqueObject[array[i][key]]) {
      uniqueArray.push(array[i]);
      uniqueObject[array[i][key]] = array[i];
    }
  }
  return uniqueArray;
}

export function CopyToClipboard(text) {
  navigator.clipboard.writeText(text);
}

export function ParseName(name, returnPlural = false) {
  if (name == null) {
    return "";
  }
  let splitName = name.split(";");
  if (returnPlural) {
    if (splitName.length > 1) {
      return splitName[1].trim();
    }
    return (splitName[0] + "s").trim();
  }
  return splitName[0].trim();
}

export function GetDayTimespan(startDate, endDate) {
  let start = new Date(startDate);
  let end = new Date(endDate);
  let days = (end - start) / (1000 * 60 * 60 * 24);
  return days;
}

export function GetLastDateOfMonth(date) {
  let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return lastDay;
}

export function GetScrollableParent(element, includeHidden) {
  var style = GetComputedStyle(element);
  var excludeStaticParent = style.position === "absolute";
  var overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;

  if (style.position === "fixed") return document.body;
  for (var parent = element; (parent = parent.parentElement); ) {
    style = GetComputedStyle(parent);
    if (excludeStaticParent && style.position === "static") {
      continue;
    }
    if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX))
      return parent;
  }

  return document.body;
}

export function GetComputedStyle(element) {
  return element.currentStyle || window.getComputedStyle(element);
}

export function CheckIfLowerHalf(element) {
  if (element != null) {
    let rect = element.getBoundingClientRect();
    let scrollableParent = GetScrollableParent(element, false);
    let parentRect = scrollableParent.getBoundingClientRect();
    let isInLowerHalf =
      rect.top > parentRect.top + scrollableParent.offsetHeight / 2;
    return isInLowerHalf;
  }
}

export async function CountPDFPages(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = function () {
      const typedarray = new Uint8Array(this.result);
      pdfjsLib.getDocument(typedarray).promise.then(
        function (pdf) {
          resolve(pdf.numPages);
        },
        function (reason) {
          reject(reason);
        }
      );
    };

    fileReader.onerror = function () {
      reject(new Error("Error reading file."));
    };

    fileReader.readAsArrayBuffer(file);
  });
}
