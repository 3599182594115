import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import Modal from "../../../../../../../Generic/Modal";
import UserItem from "./UserItem";
import AddEditUsers from "./UserActions/AddEditUsers";
import { useSiteContext } from "../../../../../../../Context";

function UserManagement(props) {
  const [addUsers, SetAddUsers] = useState(false);
  const { users, RefreshUsers, fieldCustomizations } = useSiteContext();

  useEffect(() => {
    RefreshUsers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="adminModal tableOnly">
        <div className="adminModalBodyContainer tableOnly">
          <div className="adminModalTitleBar">
            <div className="adminModalTitle">
              {fieldCustomizations.general.user.name + " Management"}
            </div>
          </div>
          <div className="adminModalBody">
            <div className="adminModalBodyItemContainer userItem header">
              <div className="adminModalBodyHeaderItem">Name</div>
              <div className="adminModalBodyHeaderItem hiddenOnSmall">
                Email
              </div>
              <div className="adminModalBodyHeaderItem">Edit</div>
              <div className="adminModalBodyHeaderItem">Perm</div>
              <div className="adminModalBodyHeaderItem">Delete</div>
            </div>
            {users.map((u) => (
              <UserItem user={u} key={u.id} RefreshUsers={RefreshUsers} />
            ))}
          </div>
          <div className="adminModalTitleBar">
            <Icon
              className="managementAddIcon"
              icon="fluent:add-circle-16-filled"
              onClick={() => SetAddUsers(true)}
            />
          </div>
        </div>
      </div>
      {addUsers ? (
        <Modal
          CloseModal={() => SetAddUsers(false)}
          modificationClass="noPaddingAlternate"
        >
          <AddEditUsers
            editMode={false}
            CloseModal={() => SetAddUsers(false)}
            // customer={props.loggedInUser.customer.id}
            RefreshUsers={RefreshUsers}
          />
        </Modal>
      ) : null}
    </>
  );
}

export default UserManagement;
