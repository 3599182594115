import React, { useEffect, useState } from "react";
import axios from "axios";
import * as Constants from "../../../../../../../../Constants";
import { useSiteContext } from "../../../../../../../../Context";
import ForagerButton from "../../../../../../../../Generic/ForagerButton";

function AddEditZohoInfo(props) {
  //eslint-disable-next-line
  const [clientId, SetClientId] = useState(props.client.id);
  const [customerName, SetCustomerName] = useState("");
  const [supportRate, SetSupportRate] = useState("");
  const [programmingRate, SetProgrammingRate] = useState("");
  const [taxRateLocation, SetTaxRateLocation] = useState("");
  const [taxExemptionReason, SetTaxExemptionReason] = useState("");
  const [zohoCustomerId, SetZohoCustomerId] = useState("");
  const [salesPersonName, SetSalesPersonName] = useState("");
  const [customerEmail, SetCustomerEmail] = useState("");

  const { AddAlert, loggedInUser, fieldCustomizations } = useSiteContext();

  function AddZohoInfo() {
    var formData = new FormData();
    formData.append("id", clientId);
    formData.append("customerName", customerName);
    formData.append("supportRate", supportRate);
    formData.append("programmingRate", programmingRate);
    if (taxRateLocation !== "") {
      formData.append("taxRateLocation", taxRateLocation);
    }
    if (taxExemptionReason !== "") {
      formData.append("taxExemptionReason", taxExemptionReason);
    }
    if (zohoCustomerId !== "") {
      formData.append("zohoCustomerId", zohoCustomerId);
    }
    if (salesPersonName !== "") {
      formData.append("salesPersonName", salesPersonName);
    }
    if (customerEmail !== "") {
      formData.append("customerEmail", customerEmail);
    }
    if (ValidateForm()) {
      axios({
        method: "post",
        url: Constants.API_URL + "Add" + Constants.API_KEY,
        headers: {
          "Content-Type": "multipart/form-data",
          UserId: loggedInUser.id,
          AddType: "ZohoInfo",
        },
        data: formData,
      })
        .then((response) => {
          if (response.data !== "Failed") {
            AddAlert("notification", "Zoho Info added successfully");
            props.RefreshClients();
            props.CloseModal();
          } else {
            AddAlert("error", "Zoho Info could not be added");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      AddAlert("error", "Please verify all required fields");
    }
  }

  function EditZohoInfo() {
    var formData = new FormData();
    formData.append("id", props.client.id);
    if (props.client.customerName !== customerName) {
      formData.append("customerName", customerName);
    }
    if (props.client.supportRate !== supportRate) {
      formData.append("supportRate", supportRate);
    }
    if (props.client.programmingRate !== programmingRate) {
      formData.append("programmingRate", programmingRate);
    }
    if (props.client.taxRateLocation !== taxRateLocation) {
      formData.append("taxRateLocation", taxRateLocation);
    }
    if (props.client.taxExemptionReason !== taxExemptionReason) {
      formData.append("taxExemptionReason", taxExemptionReason);
    }
    if (props.client.zohoCustomerId !== zohoCustomerId) {
      formData.append("zohoCustomerId", zohoCustomerId);
    }
    if (props.client.salesPersonName !== salesPersonName) {
      formData.append("salesPersonName", salesPersonName);
    }
    if (props.client.customerEmail !== customerEmail) {
      formData.append("customerEmail", customerEmail);
    }
    if (ValidateForm()) {
      axios({
        method: "patch",
        url: Constants.API_URL + "Update" + Constants.API_KEY,
        headers: {
          "Content-Type": "multipart/form-data",
          UserId: loggedInUser.id,
          UpdateType: "ZohoInfo",
        },
        data: formData,
      })
        .then((response) => {
          if (response.data !== "Failed") {
            AddAlert("notification", "Zoho Info updated successfully");
            props.RefreshClients();
            props.CloseModal();
          } else {
            AddAlert("error", "Zoho Info could not be updated");
          }
        })
        .catch((error) => {
          console.log(error);
          AddAlert("error", "Zoho Info could not be updated");
        });
    } else {
      AddAlert("error", "Please verify all required fields");
    }
  }

  function ChangeCustomerName(e) {
    SetCustomerName(e.target.value);
  }

  function ChangeSupportRate(e) {
    const regex = /^[0-9\b]+$/;
    if (
      (e.target.value === "" || regex.test(e.target.value)) &&
      e.target.value.length <= 3
    ) {
      SetSupportRate(e.target.value);
    }
  }

  function ChangeProgrammingRate(e) {
    const regex = /^[0-9\b]+$/;
    if (
      (e.target.value === "" || regex.test(e.target.value)) &&
      e.target.value.length <= 3
    ) {
      SetProgrammingRate(e.target.value);
    }
  }

  function ChangeTaxRateLocation(e) {
    SetTaxRateLocation(e.target.value);
  }

  function ChangeTaxExemptionReason(e) {
    SetTaxExemptionReason(e.target.value);
  }

  function ChangeZohoCustomerId(e) {
    SetZohoCustomerId(e.target.value);
  }

  function ChangeSalesPersonName(e) {
    SetSalesPersonName(e.target.value);
  }

  function ChangeCustomerEmail(e) {
    SetCustomerEmail(e.target.value);
  }

  function ValidateForm() {
    if (customerName !== "" && supportRate !== "" && programmingRate !== "") {
      return true;
    } else {
      return false;
    }
  }

  function SubmissionType() {
    if (props.client.zohoInfo !== null) {
      EditZohoInfo();
    } else {
      AddZohoInfo();
    }
  }

  function ResetZohoInfo() {
    if (props.client.zohoInfo !== null) {
      SetCustomerName(props.client.zohoInfo.customerName);
      SetSupportRate(props.client.zohoInfo.supportRate);
      SetProgrammingRate(props.client.zohoInfo.programmingRate);
      SetTaxRateLocation(props.client.zohoInfo.taxRateLocation);
      SetTaxExemptionReason(props.client.zohoInfo.taxExemptionReason);
      SetZohoCustomerId(props.client.zohoInfo.zohoCustomerId);
      SetSalesPersonName(props.client.zohoInfo.salesPersonName);
      SetCustomerEmail(props.client.zohoInfo.customerEmail);
    } else {
      SetCustomerName("");
      SetSupportRate("");
      SetProgrammingRate("");
      SetTaxRateLocation("");
      SetTaxExemptionReason("");
      SetZohoCustomerId("");
      SetSalesPersonName("");
      SetCustomerEmail("");
    }
  }

  useEffect(() => {
    ResetZohoInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.client]);

  return (
    <div className="adminModal">
      <div className="adminModalBodyContainer tableOnly">
        <div className="adminModalTitleBar">
          <div className="adminModalTitle">
            {props.editMode
              ? `Edit ${fieldCustomizations.general.client.name}`
              : `Add ${fieldCustomizations.general.client.name}`}
          </div>
        </div>
        <div className="adminModalBody">
          <div className="addAdminItemFormContainer oneColumn">
            <div className="adminItemInputLabel">
              <span className="addAdminItemRequiredField">*</span>Zoho Customer
              Name
            </div>
            <input
              className="ganttItemStatusValueEdit"
              type="text"
              value={customerName}
              onChange={ChangeCustomerName}
            />
            <div className="adminItemInputLabel">
              <span className="addAdminItemRequiredField">*</span>Support Rate
            </div>
            <input
              className="ganttItemStatusValueEdit"
              type="text"
              value={supportRate}
              onChange={ChangeSupportRate}
            />
            <div className="adminItemInputLabel">
              <span className="addAdminItemRequiredField">*</span>Programming
              Rate
            </div>
            <input
              className="ganttItemStatusValueEdit"
              type="text"
              value={programmingRate}
              onChange={ChangeProgrammingRate}
            />
            <div className="adminItemInputLabel">Tax Rate Location</div>
            <input
              className="ganttItemStatusValueEdit"
              type="text"
              value={taxRateLocation}
              onChange={ChangeTaxRateLocation}
            />
            <div className="adminItemInputLabel">Tax Exemption Reason</div>
            <input
              className="ganttItemStatusValueEdit"
              type="text"
              value={taxExemptionReason}
              onChange={ChangeTaxExemptionReason}
            />
            <div className="adminItemInputLabel">Zoho Customer ID</div>
            <input
              className="ganttItemStatusValueEdit"
              type="text"
              value={zohoCustomerId}
              onChange={ChangeZohoCustomerId}
            />
            <div className="adminItemInputLabel">Sales Person Name</div>
            <input
              className="ganttItemStatusValueEdit"
              type="text"
              value={salesPersonName}
              onChange={ChangeSalesPersonName}
            />
            <div className="adminItemInputLabel">Customer Email</div>
            <input
              className="ganttItemStatusValueEdit"
              type="text"
              value={customerEmail}
              onChange={ChangeCustomerEmail}
            />
          </div>
        </div>
        <div className="adminModalTitleBar threeSection">
          <div></div>
          <ForagerButton className="adminItemButton" onClick={SubmissionType}>
            {props.client.zohoInfo != null ? "Edit" : "Add"}
          </ForagerButton>
          {loggedInUser.isHammr ? (
            <ForagerButton
              className="adminItemButton"
              onClick={props.CloseModal}
              buttonType="cancel"
            >
              Cancel
            </ForagerButton>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AddEditZohoInfo;
