import React, { useState, useRef, useEffect } from "react";
import { Icon } from "@iconify/react";
import * as Utilities from "../Utilities";

function DropDownMenu(props) {
  // eslint-disable-next-line
  const [dropdownId, SetDropdownId] = useState(
    Utilities.GenerateRandomString(10)
  );
  const searchString = useRef("");
  const [processSelection, SetProcessSelection] = useState(false);
  const [showMenu, SetShowMenu] = useState(false);
  const dropdownRef = useRef(null);
  const noneSelectedLabel = props.noneSelectedLabel || "(None)";
  const options = props.options || [];
  const highlightedIdRef = useRef(props.value);
  const [highlightedId, SetHighlightedId] = useState(highlightedIdRef.current);
  const [isInLowerHalf, SetIsInLowerHalf] = useState(false);
  const [rerender, SetRerender] = useState(false);

  const selected =
    options != null && props.value != null
      ? options.find((option) => option.id === props.value)
      : null;
  const selectedLabel = selected != null ? selected.name : noneSelectedLabel;

  function GetSortedOptions() {
    if (props.dontSort) {
      return options.slice();
    }
    if (props.sortBy == null || props.sortBy === "") {
      return options.slice().sort((a, b) => {
        let value = 0;
        if (a.name.toLowerCase() > b.name.toLowerCase()) value = 1;
        if (a.name.toLowerCase() < b.name.toLowerCase()) value = -1;
        return value;
      });
    }
    return options.slice().sort((a, b) => {
      let value = 0;
      if (a[props.sortBy] > b[props.sortBy]) value = 1;
      if (a[props.sortBy] < b[props.sortBy]) value = -1;
      return value;
    });
  }

  const filteredOptions =
    props.currentlySelectedFullOption != null
      ? GetSortedOptions().filter(
          (item) => item.id !== props.currentlySelectedFullOption.id
        )
      : GetSortedOptions();

  function ShowMenu() {
    CheckIfLowerHalf();
    SetShowMenu(true);
  }

  function HideMenu() {
    SetHighlightedId(props.value);
    SetHighlightedId(highlightedIdRef.current);
    SetShowMenu(false);
  }

  function SelectItem(id) {
    props.onChange(id);
    HideMenu();
  }

  function GetMainClassName() {
    let className = "foragerDropDownMenu";
    if (props.className != null) {
      className += " " + props.className;
    }
    if (showMenu) {
      className += " showMenu";
    }
    return className;
  }

  function GetCurrentlySelectedClassName() {
    let className = "currentlySelected";
    if (selected != null && selected.colorClass != null) {
      className += " " + selected.colorClass;
    }
    return className;
  }

  function GetOptionClassName(option) {
    let className = "option";
    if (option.colorClass != null) {
      className += " " + option.colorClass;
    }
    if (option.id != null && highlightedIdRef.current === option.id) {
      className += " highlighted";
    }
    return className;
  }

  function HandleClickOutside(event) {
    if (
      dropdownRef.current != null &&
      !dropdownRef.current.contains(event.target)
    ) {
      HideMenu();
    }
  }

  function HandleKeyDown(event) {
    let input = String.fromCharCode(event.keyCode);

    if (
      /[a-zA-Z0-9]/.test(input) &&
      filteredOptions != null &&
      filteredOptions.length > 0
    ) {
      searchString.current += input;
      let setTo = null;
      let matchingOptions = filteredOptions.filter((option) =>
        option.name.toLowerCase().startsWith(searchString.current.toLowerCase())
      );
      if (matchingOptions.length > 0) {
        let foundOption = matchingOptions[0];
        if (foundOption != null) {
          setTo = foundOption.id;
        }
      }
      SetHighlightedId(setTo);
    }

    if (event.keyCode === 13 || event.keyCode === 9) {
      SetProcessSelection(true);
    }

    if (event.keyCode === 38) {
      if (highlightedIdRef.current != null) {
        let index = filteredOptions.findIndex(
          (option) => option.id === highlightedIdRef.current
        );
        if (index > 0) {
          index--;
          SetHighlightedId(filteredOptions[index].id);
        }
      } else {
        SetHighlightedId(filteredOptions[filteredOptions.length - 1].id);
      }
    }

    if (event.keyCode === 40) {
      if (highlightedIdRef.current != null) {
        let index = filteredOptions.findIndex(
          (option) => option.id === highlightedIdRef.current
        );
        if (index < filteredOptions.length - 1) {
          index++;
          SetHighlightedId(filteredOptions[index].id);
        }
      } else {
        SetHighlightedId(filteredOptions[0].id);
      }
    }
  }

  function CheckIfLowerHalf() {
    SetIsInLowerHalf(Utilities.CheckIfLowerHalf(dropdownRef.current));
  }

  useEffect(() => {
    if (showMenu) {
      document.addEventListener("keydown", HandleKeyDown);
      return () => {
        document.removeEventListener("keydown", HandleKeyDown);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMenu]);

  useEffect(() => {
    document.addEventListener("mousedown", HandleClickOutside);
    return () => {
      document.removeEventListener("mousedown", HandleClickOutside);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownRef]);

  useEffect(() => {
    if (showMenu && highlightedIdRef.current != null) {
      let element = document.getElementById(
        dropdownId + highlightedIdRef.current
      );
      if (element != null) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest",
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMenu, highlightedId, rerender]);

  useEffect(() => {
    if (processSelection) {
      SelectItem(highlightedIdRef.current);
      SetProcessSelection(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processSelection, highlightedId]);

  useEffect(() => {
    highlightedIdRef.current = highlightedId;
    SetRerender(!rerender);
    let currentTimeout = setTimeout(() => {
      searchString.current = "";
    }, 1000);
    return () => {
      clearTimeout(currentTimeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedId]);

  return (
    <div
      className={
        GetMainClassName() +
        (isInLowerHalf ? " lowerHalf" : "") +
        (props.disabled != null && props.disabled ? " disabled" : "")
      }
      ref={dropdownRef}
    >
      <div
        className={GetCurrentlySelectedClassName()}
        onClick={
          !(props.disabled != null && props.disabled)
            ? showMenu
              ? HideMenu
              : ShowMenu
            : null
        }
      >
        <div className="label">{selectedLabel}</div>
        <div className="arrowContainer">
          {showMenu ? (
            <Icon icon="fluent:chevron-up-12-regular" />
          ) : (
            <Icon icon="fluent:chevron-down-12-regular" />
          )}
        </div>
      </div>
      <div className={"anchor"}>
        {showMenu ? (
          <div className="optionsListWrapper">
            <div className="optionsList">
              {props.currentlySelectedFullOption != null ? (
                <div
                  className="option"
                  onClick={() =>
                    SelectItem(props.currentlySelectedFullOption.id)
                  }
                >
                  {props.currentlySelectedFullOption.name}
                </div>
              ) : null}
              {props.showBlank != null && props.showBlank ? (
                <div className="option" onClick={() => SelectItem(null)}>
                  {noneSelectedLabel}
                </div>
              ) : null}
              {filteredOptions.map((option, index) => (
                <div
                  id={dropdownId + option.id}
                  className={GetOptionClassName(option)}
                  key={index}
                  onClick={() => SelectItem(option.id)}
                >
                  {option.name}
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default DropDownMenu;
