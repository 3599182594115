import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { useSiteContext } from "../../../../../../../Context";
import CustomizeItem from "./CustomizeItem";
import * as Constants from "../../../../../../../Constants";
import ForagerButton from "../../../../../../../Generic/ForagerButton";
// import Modal from "../../../../../../../Generic/Modal";

function Customize(props) {
  const [customizations, SetCustomizations] = useState([]);
  const [processedCustomizations, SetProcessedCustomizations] = useState([]);

  const {
    AddAlert,
    loggedInUser,
    GetData,
    AddMultiple,
    UpdateMultiple,
    DeleteMultiple,
  } = useSiteContext();

  async function GetCustomizations() {
    let newCustomizations = await GetData("Customization", {
      customer: loggedInUser.customer.id,
    });
    if (newCustomizations instanceof Array) {
      SetCustomizations(newCustomizations);
    }
  }

  function ProcessCustomizations() {
    let newProcessedCustomizations = [];
    if (customizations.length === 0) {
      return;
    }
    for (let i = 0; i < customizations.length; i++) {
      let fieldBeingCustomized = null;
      let processedFieldId = customizations[i].fieldId;
      if (processedFieldId >= 10000) {
        processedFieldId = Math.floor(processedFieldId / 10000) * 10000;
      }
      for (let j = 0; j < Constants.allCustomizableFields.length; j++) {
        let tempFieldBeingCustomized = Constants.allCustomizableFields[
          j
        ].fields.find((field) => field.id === processedFieldId);
        if (tempFieldBeingCustomized != null) {
          fieldBeingCustomized = {
            id: tempFieldBeingCustomized.id,
            tableTypeId: Constants.allCustomizableFields[j].id,
            merged:
              Constants.allCustomizableFields[j].isMergeChild &&
              !(
                tempFieldBeingCustomized.bypassMerge != null &&
                tempFieldBeingCustomized.bypassMerge
              ),
          };
          break;
        }
      }

      if (fieldBeingCustomized == null) {
        continue;
      }
      let newCustomization = {
        id: customizations[i].id,
        tableTypeId: fieldBeingCustomized.tableTypeId,
        fieldId: processedFieldId,
        customizedName: customizations[i].customizedName,
        customizedType: customizations[i].customizedType,
        merged: fieldBeingCustomized.merged,
      };
      if (processedFieldId !== customizations[i].fieldId) {
        newCustomization.trueFieldId = customizations[i].fieldId;
      }
      newProcessedCustomizations.push(newCustomization);
    }
    SetProcessedCustomizations(newProcessedCustomizations);
  }

  function ChangeValue(id, key, value) {
    let itemIndex = processedCustomizations.findIndex((item) => item.id === id);
    let tempCustomizations = processedCustomizations.slice();
    tempCustomizations[itemIndex][key] = value;
    SetProcessedCustomizations(tempCustomizations);
  }

  function AddCustomization() {
    let currentIds = processedCustomizations.map(
      (customization) => customization.id
    );
    let maxId = 0;
    if (currentIds.length !== 0) {
      maxId = Math.max(...currentIds);
    }
    maxId++;
    let newCustomization = {
      id: maxId,
      tableTypeId: -1,
      fieldId: -2,
      customizedName: "",
      customizedType: "Text",
    };
    SetProcessedCustomizations([...processedCustomizations, newCustomization]);
  }

  function RemoveCustomization(id) {
    let newCustomizations = processedCustomizations.filter(
      (customization) => customization.id !== id
    );
    SetProcessedCustomizations(newCustomizations);
  }

  function CheckIfCustomizationChanged(customization) {
    let existingCustomization = customizations.find(
      (exCustomization) => exCustomization.id === customization.id
    );
    if (existingCustomization == null) {
      return false;
    }
    return (
      existingCustomization.customizedName !== customization.customizedName ||
      existingCustomization.customizedType !== customization.customizedType ||
      existingCustomization.fieldId !== customization.fieldId
    );
  }

  async function SaveChanges() {
    let existingCustomizations = customizations.map(
      (customization) => customization.id
    );
    let newCustomizations = processedCustomizations.filter(
      (customization) => !existingCustomizations.includes(customization.id)
    );
    let updatedCustomizations = processedCustomizations.filter(
      (customization) => existingCustomizations.includes(customization.id)
    );
    updatedCustomizations = updatedCustomizations.filter((customization) =>
      CheckIfCustomizationChanged(customization)
    );
    let deletedCustomizations = customizations.filter(
      (customization) =>
        !processedCustomizations
          .map((customization) => customization.id)
          .includes(customization.id)
    );

    if (
      newCustomizations.length === 0 &&
      updatedCustomizations.length === 0 &&
      deletedCustomizations.length === 0
    ) {
      AddAlert("notification", "No changes to save");
      return;
    }

    let updateSuccess = true;
    if (updatedCustomizations.length > 0) {
      let updates = [];
      for (let i = 0; i < updatedCustomizations.length; i++) {
        let updateData = new FormData();
        updateData.append("id", updatedCustomizations[i].id);
        updateData.append(
          "customizedName",
          updatedCustomizations[i].customizedName
        );
        updateData.append(
          "customizedType",
          updatedCustomizations[i].customizedType
        );
        updateData.append(
          "fieldId",
          updatedCustomizations[i].trueFieldId ??
            updatedCustomizations[i].fieldId
        );
        updates.push({
          type: "Customization",
          data: updateData,
        });
      }
      let updateResult = await UpdateMultiple(updates);
      if (updateResult != null && updateResult.length > 0) {
        let failed = updateResult.filter(
          (response) => response.success === false
        );
        if (failed.length > 0) {
          console.log("Update Fails", failed);
          AddAlert("error", "Failed to update customizations");
          updateSuccess = false;
        }
      } else {
        AddAlert("error", "Failed to update customizations");
        updateSuccess = false;
      }
    }

    let addSuccess = true;
    if (newCustomizations.length > 0) {
      let additions = [];
      let customFieldIdAddAmount = 1;
      for (let i = 0; i < newCustomizations.length; i++) {
        if (newCustomizations[i].fieldId >= 10000) {
          let customFieldsInSection =
            loggedInUser.customer.customizedFields.filter(
              (field) =>
                field.fieldId >= 10000 &&
                field.fieldId >= newCustomizations[i].fieldId &&
                field.fieldId < newCustomizations[i].fieldId + 10000
            );
          if (customFieldsInSection.length > 0) {
            let maxCurrentId = Math.max(
              ...customFieldsInSection.map((field) => field.fieldId)
            );
            newCustomizations[i].fieldId = maxCurrentId;
          }
          newCustomizations[i].fieldId += customFieldIdAddAmount;
          customFieldIdAddAmount++;
        }
        let newCustomizationData = new FormData();
        newCustomizationData.append("fieldId", newCustomizations[i].fieldId);
        newCustomizationData.append(
          "customizedName",
          newCustomizations[i].customizedName
        );
        newCustomizationData.append(
          "customizedType",
          newCustomizations[i].customizedType
        );
        newCustomizationData.append("customer", loggedInUser.customer.id);
        newCustomizationData.append("customerId", loggedInUser.customer.id);
        additions.push({
          id: newCustomizations[i].id,
          type: "Customization",
          data: newCustomizationData,
        });
      }
      let addResult = await AddMultiple(additions);
      if (addResult != null && addResult.length > 0) {
        let failed = addResult.filter((response) => response.success === false);
        if (failed.length > 0) {
          console.log("Add Fails", failed);
          AddAlert("error", "Failed to add some customizations");
          addSuccess = false;
        }
      } else {
        console.log("Add Fails", addResult);
        AddAlert("error", "Failed to add customizations");
        addSuccess = false;
      }
    }

    let deleteSuccess = true;
    if (deletedCustomizations.length > 0) {
      let deletions = [];
      for (let i = 0; i < deletedCustomizations.length; i++) {
        deletions.push({
          type: "Customization",
          data: { id: deletedCustomizations[i].id },
        });
      }
      let deleteResult = await DeleteMultiple(deletions);
      if (deleteResult != null && deleteResult.length > 0) {
        let failed = deleteResult.filter(
          (response) => response.success === false
        );
        if (failed.length > 0) {
          console.log("Delete Fails", failed);
          AddAlert("error", "Failed to delete some customizations");
          deleteSuccess = false;
        }
      } else {
        console.log("Delete Fails", deleteResult);
        AddAlert("error", "Failed to delete customizations");
        deleteSuccess = false;
      }
    }

    if (updateSuccess && addSuccess && deleteSuccess) {
      AddAlert(
        "notification",
        "Customizations updated successfully. This page will now refresh to show the changes."
      );
      setTimeout(() => {
        window.location = "/";
      }, 3000);
    }
  }

  useEffect(() => {
    ProcessCustomizations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customizations]);

  useEffect(() => {
    GetCustomizations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="adminModal tableOnly">
        <div className="adminModalBodyContainer tableOnly">
          <div className="adminModalTitleBar">
            <div className="adminModalTitle">Customization</div>
          </div>
          <div className="adminModalBody">
            <div className="adminModalBodyItemContainer customizeItem header">
              <div className="adminModalBodyHeaderItem">Section</div>
              <div className="adminModalBodyHeaderItem">Field</div>
              <div className="adminModalBodyHeaderItem customizeItem name">
                Name
              </div>
              <div className="adminModalBodyHeaderItem">Data Type</div>
              <div className="adminModalBodyHeaderItem">
                <Icon
                  className="managementAddIcon invertedColor"
                  icon="fluent:add-circle-16-filled"
                  onClick={AddCustomization}
                />
              </div>
            </div>
            {processedCustomizations.map((customization, index) => (
              <CustomizeItem
                key={index}
                item={customization}
                ChangeValue={ChangeValue}
                RemoveItem={RemoveCustomization}
                currentCustomizations={processedCustomizations}
              />
            ))}
          </div>
          <div className="adminModalTitleBar">
            <ForagerButton
              reversedColors={true}
              lessVerticalPadding={true}
              onClick={SaveChanges}
            >
              Save Changes
            </ForagerButton>
          </div>
        </div>
      </div>
    </>
  );
}

export default Customize;
