import React, { useEffect, useState } from "react";
import DropDownMenu from "../../../../../../../Generic/DropDownMenu";
import * as Constants from "../../../../../../../Constants";
import * as Utilities from "../../../../../../../Utilities";
import { Icon } from "@iconify/react";
import { useSiteContext } from "../../../../../../../Context";

function CustomizeItem(props) {
  const { customerSettings } = useSiteContext();

  const [customizedName, SetCustomizedName] = useState(
    props.item.customizedName
  );

  const currentTable = Constants.allCustomizableFields.find(
    (table) => table.id === props.item.tableTypeId
  );

  const availableFields =
    currentTable != null && currentTable.fields != null
      ? currentTable.fields.filter(
          (field) =>
            !props.currentCustomizations.some(
              (customization) => customization.fieldId === field.id
            ) ||
            field.id === props.item.fieldId ||
            field.id >= 10000
        )
      : [];
  const isInvalid =
    customerSettings != null &&
    !customerSettings.splitGanttItemCustomizations &&
    props.item.merged;

  function ShowTypeDropDown() {
    let field = availableFields.find(
      (field) => field.id === props.item.fieldId
    );
    if (field == null) {
      return false;
    }
    return field.id >= 10000;
  }

  function ChangeSection(value) {
    props.ChangeValue(props.item.id, "tableTypeId", value);
  }

  function ChangeField(value) {
    props.ChangeValue(props.item.id, "fieldId", value);
    let field = availableFields.find((field) => field.id === value);
    if (field == null) {
      return;
    }
    ChangeType(field.defaultType);
  }

  function ChangeName(e) {
    props.ChangeValue(props.item.id, "customizedName", e.target.value);
  }

  function ChangeType(value) {
    props.ChangeValue(props.item.id, "customizedType", value);
  }

  function RemoveItem() {
    props.RemoveItem(props.item.id);
  }

  function GetEntryTooltip() {
    if (
      currentTable != null &&
      currentTable.id === 1 &&
      customizedName !== "" &&
      customizedName != null
    ) {
      return (
        "Result : " +
        Utilities.ParseName(customizedName, false) +
        " / " +
        Utilities.ParseName(customizedName, true) +
        '<br><br>To customize the plural version, put a ";" between the singular and plural versions. For example, "Party; Parties".'
      );
    }
    return null;
  }

  function getAllCustomizableFields() {
    if (
      customerSettings != null &&
      customerSettings.splitGanttItemCustomizations
    ) {
      return Constants.allCustomizableFields;
    }
    let newAllCustomizableFields = structuredClone(
      Constants.allCustomizableFields
    );
    for (let i = 0; i < newAllCustomizableFields.length; i++) {
      if (
        !newAllCustomizableFields[i].isMergeChild &&
        newAllCustomizableFields[i].mergedName != null &&
        newAllCustomizableFields[i].id !== props.item.id
      ) {
        newAllCustomizableFields[i].name =
          newAllCustomizableFields[i].mergedName;
        continue;
      }
      newAllCustomizableFields[i].fields = newAllCustomizableFields[
        i
      ].fields.filter(
        (field) => !(field.bypassMerge != null && field.bypassMerge)
      );
    }
    newAllCustomizableFields = newAllCustomizableFields.filter(
      (table) => table.fields != null || table.fields.length === 0
    );
    return newAllCustomizableFields;
  }

  useEffect(() => {
    SetCustomizedName(props.item.customizedName);
  }, [props.item.customizedName]);

  return (
    <div
      className={
        "adminModalBodyItemContainer customizeItem" +
        (isInvalid ? " invalid" : "")
      }
      data-tip={
        isInvalid
          ? "This item is will be overriden based on your customer settings. (Split Schedule Item Customizations)"
          : null
      }
    >
      <div className="adminModalTableValue customizeItem section">
        <DropDownMenu
          options={getAllCustomizableFields()}
          value={props.item.tableTypeId}
          onChange={ChangeSection}
          sortBy="orderInList"
        />
      </div>
      <div className="adminModalTableValue customizeItem field">
        <DropDownMenu
          options={availableFields}
          value={props.item.fieldId}
          onChange={ChangeField}
          sortBy="orderInList"
        />
      </div>
      <div className="adminModalTableValue customizeItem name">
        <input
          className="ganttItemStatusValueEdit"
          type="text"
          value={customizedName}
          onChange={ChangeName}
          data-tip={GetEntryTooltip()}
          data-multiline={true}
        />
      </div>
      <div className="adminModalTableValue customizeItem">
        {ShowTypeDropDown() ? (
          <DropDownMenu
            options={Constants.fieldTypeOptions}
            value={props.item.customizedType}
            onChange={ChangeType}
            dontSort={true}
          />
        ) : (
          props.item.customizedType
        )}
      </div>
      <div className="adminModalTableValue delete">
        <Icon icon="gridicons:trash" onClick={RemoveItem} />
      </div>
    </div>
  );
}

export default CustomizeItem;
