import React from "react";
import * as Constants from "../../../../../../../../Constants";
import { Icon } from "@iconify/react";
import DropDownMenu from "../../../../../../../../Generic/DropDownMenu";

function SettingsTableItem(props) {
  const countedAsActiveOptions = [
    { name: "Yes", id: true },
    { name: "No", id: false },
  ];

  const orderOptions = [
    { name: "01", id: 1 },
    { name: "02", id: 2 },
    { name: "03", id: 3 },
    { name: "04", id: 4 },
    { name: "05", id: 5 },
    { name: "06", id: 6 },
    { name: "07", id: 7 },
    { name: "08", id: 8 },
    { name: "09", id: 9 },
    { name: "10", id: 10 },
    { name: "11", id: 11 },
    { name: "12", id: 12 },
    { name: "13", id: 13 },
    { name: "14", id: 14 },
    { name: "15", id: 15 },
    { name: "16", id: 16 },
    { name: "17", id: 17 },
    { name: "18", id: 18 },
    { name: "19", id: 19 },
    { name: "20", id: 20 },
  ];

  function ChangeDefault() {
    props.ChangeCustomerSettingsValue("defaultStatus", props.item.id);
  }

  function ChangeOrder(value) {
    props.ChangeValue(props.item.id, "listOrder", value);
  }

  function ChangeName(e) {
    props.ChangeValue(props.item.id, "name", e.target.value);
  }

  function ChangeCountedAsActive(value) {
    props.ChangeValue(props.item.id, "countedAsActive", value);
  }

  function ChangeCountedAsComplete(value) {
    props.ChangeValue(props.item.id, "countedAsComplete", value);
  }

  function ChangeColor(value) {
    props.ChangeValue(props.item.id, "color", value);
  }

  function RemoveItem() {
    props.RemoveItem(props.item.id);
  }

  return (
    <div className="adminModalBodyItemContainer statusItem">
      <div className="adminModalTableValue statusItem" onClick={ChangeDefault}>
        {props.isCurrentDefault ? <Icon icon="gridicons:checkmark" /> : ""}
      </div>
      <div className="adminModalTableValue statusItem order">
        <DropDownMenu
          options={orderOptions}
          value={props.item.listOrder}
          onChange={ChangeOrder}
        />
      </div>
      <div className="adminModalTableValue statusItem name">
        <input
          className="ganttItemStatusValueEdit"
          type="text"
          value={props.item.name}
          onChange={ChangeName}
        />
      </div>
      <div className="adminModalTableValue statusItem active">
        <DropDownMenu
          options={countedAsActiveOptions}
          value={props.item.countedAsActive}
          onChange={ChangeCountedAsActive}
        />
      </div>
      <div className="adminModalTableValue statusItem complete">
        <DropDownMenu
          options={countedAsActiveOptions}
          value={props.item.countedAsComplete}
          onChange={ChangeCountedAsComplete}
        />
      </div>
      <div className="adminModalTableValue statusItem color">
        <DropDownMenu
          options={Constants.colorOptions}
          value={props.item.color}
          onChange={ChangeColor}
          useColorClass={true}
        />
      </div>
      <div className="adminModalTableValue statusItem delete">
        <Icon icon="gridicons:trash" onClick={RemoveItem} />
      </div>
    </div>
  );
}

export default SettingsTableItem;
