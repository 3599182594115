import React, { useState } from "react";
import axios from "axios";
import * as Constants from "../../../../../../../../Constants";
import { useSiteContext } from "../../../../../../../../Context";
import DropDownMenu from "../../../../../../../../Generic/DropDownMenu";
import ForagerButton from "../../../../../../../../Generic/ForagerButton";
import Modal from "../../../../../../../../Generic/Modal";
import AddEditZohoInfo from "./AddEditZohoInfo";

function AddEditClient(props) {
  const [address1, SetAddress1] = useState(
    props.editMode ? props.client.address1 : ""
  );
  const [address2, SetAddress2] = useState(
    props.editMode ? props.client.address2 ?? "" : ""
  );
  const [name, SetName] = useState(props.editMode ? props.client.name : "");
  const [city, SetCity] = useState(props.editMode ? props.client.city : "");
  const [state, SetState] = useState(props.editMode ? props.client.state : "");
  const [zip, SetZip] = useState(props.editMode ? props.client.zip : "");
  const [phone, SetPhone] = useState(
    props.editMode ? props.client.phone.toString() : ""
  );
  const { AddAlert, loggedInUser, fieldCustomizations } = useSiteContext();
  const [zohoModalOpen, SetZohoModalOpen] = useState(false);

  function AddClient() {
    var formData = new FormData();
    formData.append("customer", loggedInUser.customer.id);
    formData.append("customerId", loggedInUser.customer.id);
    formData.append("name", name);
    if (address1 !== "") {
      formData.append("address1", address1);
    }
    if (address2 !== "") {
      formData.append("address2", address2);
    }
    if (city !== "") {
      formData.append("city", city);
    }
    if (state !== "") {
      formData.append("state", state);
    }
    if (zip !== "") {
      formData.append("zip", zip);
    }
    if (phone !== "") {
      formData.append("phone", phone);
    }
    if (ValidateForm()) {
      axios({
        method: "post",
        url: Constants.API_URL + "Add" + Constants.API_KEY,
        headers: {
          "Content-Type": "multipart/form-data",
          UserId: loggedInUser.id,
          AddType: "Client",
        },
        data: formData,
      })
        .then((response) => {
          if (response.data !== "Failed") {
            AddAlert(
              "notification",
              `${fieldCustomizations.general.client.name} added successfully`
            );
            props.RefreshClients();
            props.CloseModal();
          } else {
            AddAlert(
              "error",
              `${fieldCustomizations.general.client.name} could not be added`
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      AddAlert("error", "Please verify all required fields");
    }
  }

  function EditClient() {
    var formData = new FormData();
    formData.append("id", props.client.id);
    if (props.client.name !== name) {
      formData.append("name", name);
    }
    if (props.client.address1 !== address1) {
      formData.append("address1", address1);
    }
    if (props.client.address2 !== address2) {
      if (address2 !== "") {
        formData.append("address2", address2);
      }
    }
    if (props.client.city !== city) {
      formData.append("city", city);
    }
    if (props.client.state !== state) {
      formData.append("state", state);
    }
    if (props.client.zip !== zip) {
      formData.append("zip", zip);
    }
    if (props.client.phone !== phone) {
      formData.append("phone", phone);
    }
    if (ValidateForm()) {
      axios({
        method: "patch",
        url: Constants.API_URL + "Update" + Constants.API_KEY,
        headers: {
          "Content-Type": "multipart/form-data",
          UserId: loggedInUser.id,
          UpdateType: "Client",
        },
        data: formData,
      })
        .then((response) => {
          if (response.data !== "Failed") {
            AddAlert("notification", "Client updated successfully");
            props.RefreshClients();
            props.CloseModal();
          } else {
            AddAlert("error", "Client could not be updated");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      AddAlert("error", "Please verify all required fields");
    }
  }

  function ChangeName(e) {
    SetName(e.target.value);
  }

  function ChangeAddress1(e) {
    SetAddress1(e.target.value);
  }

  function ChangeAddress2(e) {
    SetAddress2(e.target.value);
  }

  function ChangeCity(e) {
    SetCity(e.target.value);
  }

  function ChangeState(value) {
    SetState(value);
  }

  function ChangeZip(e) {
    let numbersOnly = e.target.value.replace(/\D/g, "");
    const regex = /^[0-9\b]+$/;
    if (
      (numbersOnly === "" || regex.test(numbersOnly)) &&
      numbersOnly.length <= 9
    ) {
      SetZip(numbersOnly);
    }
  }

  function ChangePhone(e) {
    let numbersOnly = e.target.value.replace(/\D/g, "");
    const regex = /^[0-9\b]+$/;
    if (
      (numbersOnly === "" || regex.test(numbersOnly)) &&
      numbersOnly.length <= 10
    ) {
      SetPhone(numbersOnly);
    }
  }

  function OpenZohoModal() {
    console.log("open zoho modal");
    SetZohoModalOpen(true);
  }

  function CloseZohoModal() {
    SetZohoModalOpen(false);
  }

  function ValidateForm() {
    if (name !== "") {
      return true;
    } else {
      return false;
    }
  }

  function SubmissionType() {
    if (props.editMode) {
      EditClient();
    } else {
      AddClient();
    }
  }

  return (
    <div className="adminModal">
      <div className="adminModalBodyContainer tableOnly">
        <div className="adminModalTitleBar">
          <div className="adminModalTitle">
            {props.editMode
              ? "Edit " + fieldCustomizations.general.client.name
              : "Add " + fieldCustomizations.general.client.name}
          </div>
        </div>
        <div className="adminModalBody">
          <div className="addAdminItemFormContainer">
            <div className="adminItemInputLabel email">
              <span className="addAdminItemRequiredField">*</span>Name
            </div>
            <input
              className="ganttItemStatusValueEdit formWidth2"
              type="text"
              value={name}
              onChange={ChangeName}
            />
            <div className="adminItemInputLabel">Address 1</div>
            <input
              className="ganttItemStatusValueEdit"
              type="text"
              value={address1}
              onChange={ChangeAddress1}
            />
            <div className="adminItemInputLabel">Address 2</div>
            <input
              className="ganttItemStatusValueEdit"
              type="text"
              value={address2}
              onChange={ChangeAddress2}
            />
            <div className="adminItemInputLabel">City</div>
            <input
              className="ganttItemStatusValueEdit"
              type="text"
              value={city}
              onChange={ChangeCity}
            />
            <div className="adminItemInputLabel">State</div>
            <div className="ganttItemStatusDropDownContainer">
              <DropDownMenu
                className="ganttItemStatusDropDown"
                value={state}
                onChange={ChangeState}
                options={Constants.states}
                sortBy="country"
              />
            </div>
            <div className="adminItemInputLabel">Zip</div>
            <input
              className="ganttItemStatusValueEdit"
              type="text"
              value={zip}
              onChange={ChangeZip}
            />
            <div className="adminItemInputLabel">Phone</div>
            <input
              className="ganttItemStatusValueEdit"
              type="text"
              value={phone}
              onChange={ChangePhone}
            />
          </div>
        </div>
        <div className="adminModalTitleBar threeSection">
          <div></div>
          <ForagerButton className="adminItemButton" onClick={SubmissionType}>
            {props.editMode ? "Update" : "Submit"}
          </ForagerButton>
          {loggedInUser.isHammr ? (
            <ForagerButton
              className="adminItemButton"
              onClick={OpenZohoModal}
              buttonType="cancel"
            >
              Zoho Info
            </ForagerButton>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      {zohoModalOpen ? (
        <Modal
          CloseModal={CloseZohoModal}
          modificationClass="noPaddingAlternate"
        >
          <AddEditZohoInfo
            client={props.client}
            editMode={true}
            CloseModal={CloseZohoModal}
            RefreshClients={props.RefreshClients}
          />
        </Modal>
      ) : null}
    </div>
  );
}

export default AddEditClient;
