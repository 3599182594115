import React from "react";
import Permission from "./Permission";

function PermissionsSection(props) {
  return (
    <div className="adminModalSectionContainer">
      <div className="adminModalSectionTitle">
        {props.permissionsSection.name}
      </div>
      <div className="adminModalSection">
        {props.permissionsSection.permissions.map((p) => (
          <Permission
            permission={p}
            key={p.id}
            currentPermissions={props.currentPermissions}
            addPermissions={props.addPermissions}
            removePermissions={props.removePermissions}
            PermissionChange={props.PermissionChange}
          />
        ))}
      </div>
    </div>
  );
}

export default PermissionsSection;
