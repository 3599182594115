import * as Constants from "../Constants";

class Customizations {
  constructor(loggedInUser, customerSettings) {
    this.loggedInUser = loggedInUser;
    this.customerSettings = customerSettings;
    this.loadedFromLogin = loggedInUser != null && customerSettings != null;
    this.general = this.GenerateCustomizations(1);
    this.job = this.GenerateCustomizations(2);
    this.task = this.GenerateCustomizations(3, 2);
  }

  GenerateCustomizations(id, mergeToId = null) {
    let Customizations = {};
    let currentGroup = Constants.allCustomizableFields.find(
      (field) => field.id === id
    );
    let mergeToGroup = null;
    if (
      this.customerSettings != null &&
      !this.customerSettings.splitGanttItemCustomizations &&
      mergeToId != null
    ) {
      mergeToGroup = Constants.allCustomizableFields.find(
        (field) => field.id === mergeToId
      );
    }
    mergeToId = mergeToGroup?.id;
    if (currentGroup == null) {
      console.log("Error: Invalid Customization Group ID");
      return;
    }
    for (let i = 0; i < currentGroup.fields.length; i++) {
      if (mergeToGroup != null) {
        let mergeToField = mergeToGroup.fields.find(
          (field) => field.fieldName === currentGroup.fields[i].fieldName
        );
        if (mergeToField != null) {
          Customizations[currentGroup.fields[i].fieldName] =
            this.ReturnCustomizedField(
              mergeToField.id,
              mergeToField.name,
              mergeToField.defaultType,
              mergeToField.isCustomField,
              true
            );
          continue;
        }
      }
      Customizations[currentGroup.fields[i].fieldName] =
        this.ReturnCustomizedField(
          currentGroup.fields[i].id,
          currentGroup.fields[i].name,
          currentGroup.fields[i].defaultType,
          currentGroup.fields[i].isCustomField
        );
    }

    let idModifier = 10000 * ((mergeToId ?? id) - 2);
    let customFields = this.loggedInUser?.customer?.customizedFields?.filter(
      (f) => f.fieldId >= 10000 + idModifier && f.fieldId < 20000 + idModifier
    );
    if (customFields != null) {
      for (let i = 0; i < customFields.length; i++) {
        let field = customFields[i];
        Customizations[field.fieldId] = {
          fieldId: field.fieldId,
          name: field.singularName,
          pluralName: field.pluralName,
          type: field.customizedType,
          isActive: true,
          merged: mergeToId != null,
          isCustomField: true,
        };
      }
    }

    return Customizations;
  }

  ReturnCustomizedField(
    fieldId,
    defaultName,
    defaultType,
    isCustomField = false,
    merged = false
  ) {
    let field = {
      fieldId: fieldId,
      name: defaultName,
      pluralName: defaultName + "s",
      type: defaultType,
      isActive: false,
      isCustomField: isCustomField,
    };
    if (
      this.loggedInUser?.customer?.customizedFields == null ||
      this.loggedInUser?.customer?.customizedFields.length === 0
    ) {
      return field;
    }
    let newFieldId = fieldId;
    if (
      this.customerSettings != null &&
      this.customerSettings.splitGanttItemCustomizations
    ) {
      let sharedCustomization = Constants.allSharedCustomizations.find(
        (field) => field.subId === fieldId
      );
      if (sharedCustomization != null) {
        newFieldId = sharedCustomization.mainId;
      }
    }
    let customization = this.loggedInUser.customer.customizedFields.find(
      (field) => field.fieldId === newFieldId
    );
    if (customization == null) {
      return field;
    }
    field.fieldId = customization.fieldId;
    field.name = customization.singularName;
    field.pluralName = customization.pluralName;
    field.type = customization.customizedType;
    field.isActive = true;
    field.merged = merged;
    return field;
  }
}

export default Customizations;
