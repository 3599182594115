import React, { useState, useEffect } from "react";
import { useSiteContext } from "../../../../../../../Context";
import { Icon } from "@iconify/react";
import SettingsTableItem from "./Items/SettingsTableItem";
import ForagerButton from "../../../../../../../Generic/ForagerButton";
import * as Utilities from "../../../../../../../Utilities";

function CustomerSettings(props) {
  const [newStatuses, SetNewStatuses] = useState([]);
  const [newCustomerSettings, SetNewCustomerSettings] = useState(null);

  const {
    loggedInUser,
    AddMultiple,
    UpdateData,
    UpdateMultiple,
    statuses,
    RefreshStatuses,
    AddAlert,
    customerSettings,
    RefreshCustomerSettings,
  } = useSiteContext();

  function ChangeCustomerSettingsValue(key, value) {
    props.SetEditsPending(true);
    let tempSettings = { ...newCustomerSettings };
    switch (key) {
      case "defaultStatus":
        tempSettings.status.id = value;
        break;
      default:
        tempSettings[key] = value;
        break;
    }
    SetNewCustomerSettings(tempSettings);
  }

  function ChangeValue(id, key, value) {
    props.SetEditsPending(true);
    let itemIndex = newStatuses.findIndex((item) => item.id === id);
    let tempStatuses = newStatuses.slice();
    tempStatuses[itemIndex][key] = value;
    SetNewStatuses(tempStatuses);
  }

  function AddNewStatus() {
    let currentIds = newStatuses.map((status) => status.id);
    let maxId = Math.max(...currentIds);
    let newStatus = {
      id: maxId + 1,
      name: "",
      color: 1,
      countedAsActive: false,
      countedAsComplete: false,
      listOrder: newStatuses.length + 1,
      isAvailable: true,
    };
    SetNewStatuses([...newStatuses, newStatus]);
  }

  function RemoveStatus(id) {
    let tempStatuses = newStatuses.filter((status) => status.id !== id);
    SetNewStatuses(tempStatuses);
  }

  async function UpdateCustomerSettings() {
    let customerSettingsChanged = CheckIfCustomerSettingsChanged();
    let statusesChanged = CheckIfStatusesChanged();
    let allSuccess = true;
    if (customerSettingsChanged.length > 0) {
      let params = new FormData();
      params.append("id", newCustomerSettings.id);
      for (let i = 0; i < customerSettingsChanged.length; i++) {
        let key = customerSettingsChanged[i];
        if (key === "status.id") {
          params.append("defaultStatusId", newCustomerSettings.status.id);
        } else {
          params.append(key, newCustomerSettings[key]);
        }
      }
      let response = await UpdateData("CustomerSettings", params);
      if (
        response == null ||
        (response != null && !response.includes("Successful"))
      ) {
        allSuccess = false;
        AddAlert("error", "There was an error updating the customer settings.");
      }
    }
    if (statusesChanged) {
      let success = await UpdateStatuses();
      if (!success) {
        allSuccess = false;
        AddAlert("error", "There was an error updating the statuses.");
      }
    }
    if (allSuccess) {
      RefreshCustomerSettings();
      RefreshStatuses();
      AddAlert("notification", "Successfully updated customer settings.");
      props.CloseModal(true);
    }
  }

  function CheckIfCustomerSettingsChanged() {
    let partsChanged = Utilities.CheckIfObjectChanged(
      newCustomerSettings,
      customerSettings
    );
    return partsChanged;
  }

  function CheckIfStatusesChanged() {
    if (newStatuses.length !== statuses.length) {
      return true;
    }
    for (let i = 0; i < newStatuses.length; i++) {
      let status = newStatuses[i];
      let oldStatus = statuses.find((oStatus) => oStatus.id === status.id);
      if (oldStatus == null) {
        return true;
      }
      let partsChanged = Utilities.CheckIfObjectChanged(status, oldStatus);
      if (partsChanged.length > 0) {
        return true;
      }
    }
    return false;
  }

  async function UpdateStatuses() {
    let dataUpdateItems = [];
    let isRemoving = false;
    for (let i = 0; i < statuses.length; i++) {
      let status = statuses[i];
      let newStatus = newStatuses.find(
        (newStatus) => newStatus.id === status.id
      );
      if (newStatus == null) {
        isRemoving = true;
        let removeData = new FormData();
        removeData.append("id", status.id);
        removeData.append("isAvailable", false);
        let removeItem = {
          type: "Status",
          data: removeData,
        };
        dataUpdateItems.push(removeItem);
      } else {
        let partsChanged = Utilities.CheckIfObjectChanged(status, newStatus);
        if (partsChanged.length > 0) {
          let params = new FormData();
          params.append("id", status.id);
          for (let j = 0; j < partsChanged.length; j++) {
            let part = partsChanged[j];
            params.append(part, newStatus[part]);
          }
          let updateItem = {
            type: "Status",
            data: params,
          };
          dataUpdateItems.push(updateItem);
        }
      }
    }
    let addedItems = newStatuses.filter(
      (newStatus) => !statuses.some((status) => status.id === newStatus.id)
    );
    let dataAddedItems = [];
    if (addedItems.length > 0) {
      for (let i = 0; i < addedItems.length; i++) {
        let addedItem = addedItems[i];
        let params = new FormData();
        params.append("customer", loggedInUser.customer.id);
        params.append("customerId", loggedInUser.customer.id);
        params.append("name", addedItem.name);
        params.append("color", addedItem.color);
        params.append("countedAsActive", addedItem.countedAsActive);
        params.append("countedAsComplete", addedItem.countedAsComplete);
        params.append("listOrder", addedItem.listOrder);
        params.append("isAvailable", addedItem.isAvailable);
        let addItem = {
          type: "Status",
          data: params,
        };
        dataAddedItems.push(addItem);
      }
    }
    if (isRemoving) {
      let confirmed = window.confirm(
        "You have deleted a status. This is a permanent action. Are you sure you want to continue?"
      );
      if (!confirmed) {
        return;
      }
    }
    let updateSuccess = true;
    if (dataUpdateItems.length > 0) {
      let responses = await UpdateMultiple(dataUpdateItems);
      if (responses != null && responses.length > 0) {
        let failed = responses.filter((response) => response.success === false);
        if (failed.length > 0) {
          console.log("Update Fails", failed);
          AddAlert("Failed to update some statuses");
          updateSuccess = false;
        }
      } else {
        console.log("Update Fails", responses);
        AddAlert("Failed to update statuses");
        updateSuccess = false;
      }
    }
    let addSuccess = true;
    if (dataAddedItems.length > 0) {
      let responses = await AddMultiple(dataAddedItems);
      if (responses != null && responses.length > 0) {
        let failed = responses.filter((response) => response.success === false);
        if (failed.length > 0) {
          console.log("Add Fails", failed);
          AddAlert("Failed to add some statuses");
          addSuccess = false;
        }
      } else {
        console.log("Add Fails", responses);
        AddAlert("Failed to add statuses");
        addSuccess = false;
      }
    }

    return updateSuccess && addSuccess;
  }

  async function ResetStatuses() {
    if (statuses != null && statuses.length > 0) {
      let sortedStatuses = structuredClone(statuses);
      sortedStatuses.sort((a, b) => a.listOrder - b.listOrder);
      SetNewStatuses(sortedStatuses);
    }
  }

  useEffect(() => {
    SetNewCustomerSettings(structuredClone(customerSettings));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSettings]);

  useEffect(() => {
    ResetStatuses();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statuses]);

  useEffect(() => {
    RefreshStatuses();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="adminModal customerSettings">
      <div className="adminModalTitleBar">
        <div className="adminModalTitle">Customer Settings</div>
      </div>
      {newCustomerSettings != null ? (
        <div className="adminModalBody paddedInternal">
          <div className="adminModalInnerTable">
            <div className="adminModalTitleBar threeSection">
              <div className="adminModalTitle"></div>
              <div className="adminModalTitle">Statuses</div>
              <div className="adminModalTitle rightJustified">
                <Icon
                  icon="vaadin:plus-circle"
                  className="addIcon"
                  onClick={AddNewStatus}
                />
              </div>
            </div>
            <div className="adminModalBody">
              <div className="adminModalBodyItemContainer statusItem header">
                <div className="adminModalBodyHeaderItem statusItem">
                  Default
                </div>
                <div className="adminModalBodyHeaderItem statusItem order">
                  Order
                </div>
                <div className="adminModalBodyHeaderItem statusItem name">
                  Name
                </div>
                <div className="adminModalBodyHeaderItem statusItem active">
                  Active
                </div>
                <div className="adminModalBodyHeaderItem statusItem complete">
                  Complete
                </div>
                <div className="adminModalBodyHeaderItem statusItem color">
                  Color
                </div>
                <div className="adminModalBodyHeaderItem statusItem delete"></div>
              </div>
              {newStatuses.map((status, index) => (
                <SettingsTableItem
                  key={index}
                  order={index + 1}
                  item={status}
                  color={1}
                  type="Status"
                  RefreshItems={ResetStatuses}
                  isCurrentDefault={status.id === newCustomerSettings.status.id}
                  ChangeCustomerSettingsValue={ChangeCustomerSettingsValue}
                  RemoveItem={RemoveStatus}
                  ChangeValue={ChangeValue}
                />
              ))}
            </div>
          </div>
          <div className="permissionContainer">
            <input
              className="permissionCheckbox"
              type="checkbox"
              checked={newCustomerSettings.splitGanttItemCustomizations}
              onChange={() => {
                ChangeCustomerSettingsValue(
                  "splitGanttItemCustomizations",
                  !newCustomerSettings.splitGanttItemCustomizations
                );
              }}
            />
            <div className="permissionTitle">
              Split Schedule Item Customizations
            </div>
          </div>
        </div>
      ) : (
        <div className="adminModalBody paddedInternal"></div>
      )}
      <div className="adminModalTitleBar">
        <ForagerButton reversedColors={true} onClick={UpdateCustomerSettings}>
          Save
        </ForagerButton>
      </div>
    </div>
  );
}

export default CustomerSettings;
