import React, { useState } from "react";
import { Icon } from "@iconify/react";
import Modal from "../../../../../../../Generic/Modal";
import AddEditCustomer from "./CustomerActions/AddEditCustomer";

function CustomerItem(props) {
  const [showAddEditCustomerModal, SetShowAddEditCustomerModal] =
    useState(false);

  function AddEditCustomerModal() {
    SetShowAddEditCustomerModal(!showAddEditCustomerModal);
  }

  return (
    <>
      <div className="adminModalBodyItemContainer customerItem">
        <div className="adminModalTableValue">{props.customer.name}</div>
        <div className="adminModalTableValue hiddenOnSmall">
          {props.customer.phone}
        </div>
        <div className="adminModalTableValue">
          <Icon
            className="editIcon"
            icon="bxs:edit"
            onClick={AddEditCustomerModal}
          />
        </div>
        <div className="adminModalTableValue">
          <Icon className="featureIcon" icon="fa6-solid:sliders" />
        </div>
      </div>
      {showAddEditCustomerModal ? (
        <Modal
          CloseModal={AddEditCustomerModal}
          modificationClass="noPaddingAlternate"
        >
          <AddEditCustomer
            editMode={true}
            CloseModal={AddEditCustomerModal}
            customer={props.customer}
            RefreshCustomers={props.RefreshCustomers}
          />
        </Modal>
      ) : null}
    </>
  );
}

export default CustomerItem;
